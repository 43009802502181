import { Stack, Typography } from "@mui/material";

const LabelWithIcon = ({ label, Icon = () => null, size, dark }) => (
  <Stack useFlexGap direction="row" alignItems="center" spacing={1} pb={1.5}>
    <Icon
      sx={{
        width: size,
        height: size,
        color: dark ? "surface.inverse.on" : "surface.on.color"
      }}
    />
    <Typography
      variant="titleMd"
      color={dark ? "surface.inverse.on" : "surface.on.color"}
      textTransform="capitalize"
    >
      {label}
    </Typography>
  </Stack>
);

export default LabelWithIcon;
