"use client";
import { useEffect } from "react";
import dynamic from "next/dynamic";
import { Box } from "@mui/material";

import { useStore, observer } from "../../service/mobx";

const SwipeableDrawer = dynamic(() => import("@mui/material/SwipeableDrawer"));
const DragIcon = dynamic(() =>
  import("@mui/icons-material/HorizontalRuleRounded")
);

function BottomSheet() {
  const { device, bottomSheet, bottomNav } = useStore();
  // only show bottom sheet on small screens
  useEffect(() => {
    if (bottomSheet.open && device.isPhone === false) {
      bottomSheet.set.open(false);
    }
  }, [bottomSheet, device.isPhone]);

  return device.isDesktop ? null : (
    <SwipeableDrawer
      disableDiscovery
      disableSwipeToOpen
      anchor="bottom"
      open={bottomSheet.open}
      onClose={bottomSheet.onClose}
      onOpen={() => {}}
      sx={{ zIndex: 1301 }}
      ModalProps={{ keepMounted: false }}
      PaperProps={{
        sx: theme => ({
          p: 2,
          maxHeight: "70vh",
          overflow: "overlay",
          m: { compact: 0, large: 56 },
          pb: bottomNav.loaded ? 10 : undefined,
          bgcolor: "surface.container.low",
          borderRadius: theme.shape.xl.top,
          boxShadow: theme.elevation[1],
          ...theme.motion.transition[bottomSheet.open ? "enter" : "exit"].perm,
          ...bottomSheet.sx
        })
      }}
      SlideProps={{
        mountOnEnter: true,
        unmountOnExit: true,
        onExited: bottomSheet.reset
      }}
    >
      <Box
        top={-1}
        zIndex={1}
        position="sticky"
        bgcolor={bottomSheet.sx?.bgcolor ?? "surface.container.low"}
        sx={{ transform: "translateY(-16px)" }}
      >
        <Box height={48} display="flex" justifyContent="center">
          <DragIcon
            sx={{
              width: 32,
              height: 32,
              opacity: 0.4,
              alignSelf: "center",
              color: "surface.on.variant"
            }}
          />
        </Box>
      </Box>
      <bottomSheet.Component />
    </SwipeableDrawer>
  );
}

export default observer(BottomSheet);
