"use client";
import { useEffect } from "react";
import {
  IconButton,
  Slide,
  Dialog,
  Typography,
  Stack,
  Button,
  Box,
  Collapse
} from "@mui/material";
import { Close as CloseIcon, ArrowBack as BackIcon } from "@mui/icons-material";

import { useStore, observer } from "../../service/mobx";

function DialogComponent() {
  const { device, dialog } = useStore();
  const fullScreen = dialog.fullScreen || device.isPhone;

  useEffect(() => {
    dialog.reset();
  }, [dialog, device.isPhone]);

  return (
    <Dialog
      key={dialog.Content}
      maxWidth="extraLarge"
      sx={{
        "& .MuiDialog-scrollPaper": {
          height: "100vh"
        }
      }}
      PaperProps={{
        sx: theme => ({
          p: 3,
          pt: { compact: 0, expanded: 0 },
          bgcolor: fullScreen
            ? "surface.container.highest"
            : "surface.container.high",
          width: fullScreen ? undefined : 560,
          boxShadow: theme.elevation[3],
          maxHeight: fullScreen ? undefined : "50vh",
          borderRadius: fullScreen ? 0 : theme.shape.xl.round,
          ...dialog.sx.paper
        })
      }}
      slotProps={{
        backdrop: {
          sx: theme => ({
            backgroundColor: "rgba(0,0,0,.32)",
            ...theme.motion.transition[dialog.open ? "enter" : "exit"].perm,
            ...dialog.sx.scrim
          })
        }
      }}
      open={dialog.open}
      onClose={() => dialog.set.open(false)}
      fullScreen={fullScreen}
      TransitionComponent={fullScreen ? Slide : Collapse}
      TransitionProps={{
        appear: true,
        mountOnEnter: true,
        unmountOnExit: true,
        timeout: { enter: 500, exit: 200 },
        onExited: dialog.reset,
        direction: fullScreen ? "up" : undefined,
        sx: theme => ({
          alignItems: { compact: "flex-end", expanded: "center" },
          ...theme.motion.transition[dialog.open ? "enter" : "exit"].perm
        })
      }}
      {...dialog.props}
    >
      <Stack spacing={2} sx={dialog.sx.firstStack}>
        {dialog.headline || device.fullScreen ? (
          <Stack
            useFlexGap
            direction="row"
            alignItems="center"
            height={56}
            spacing={2}
            mx={{ compact: "-16px !important", expanded: "0 !important" }}
            mb={{ compact: 0 }}
            position="sticky"
            top={0}
            pt={{ compact: 1, expanded: 3 }}
            zIndex={10}
            bgcolor={
              dialog.sx?.paper?.bgcolor ?? fullScreen
                ? "surface.container.highest"
                : "surface.container.high"
            }
            sx={dialog.sx.headline}
          >
            {dialog.BackIconCallback ? (
              <IconButton onClick={dialog.BackIconCallback}>
                <BackIcon sx={{ color: "surface.on.color" }} />
              </IconButton>
            ) : fullScreen ? (
              <IconButton onClick={dialog.toggle}>
                <CloseIcon sx={{ color: "surface.on.color" }} />
              </IconButton>
            ) : null}
            <Typography
              variant="headlineSm"
              color="surface.on.color"
              sx={{
                typography: { compact: "titleLg", expanded: "headlineSm" },
                ...dialog.sx.typography
              }}
            >
              {dialog.headline}
            </Typography>
            {dialog.headlineActions.length ? (
              <Box ml="auto">
                {dialog.headlineActions.map(({ label, ...props }) => (
                  <Button key={label} size="large" {...props}>
                    {label}
                  </Button>
                ))}
              </Box>
            ) : null}
          </Stack>
        ) : null}
        {dialog.Content ? <dialog.Content /> : null}
      </Stack>
    </Dialog>
  );
}

export default observer(DialogComponent);
