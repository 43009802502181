"use client";
import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";

import { useStore, observer } from "../../service/mobx";

const Menu = dynamic(() => import("@mui/material/Menu"));
const Collapse = dynamic(() => import("@mui/material/Collapse"));
const Fade = dynamic(() => import("@mui/material/Fade"));

export default observer(function MenuComponent() {
  const [show, setShow] = useState(false);
  const { device, menu, bottomSheet } = useStore();
  const pathname = usePathname();

  useEffect(() => {
    if (device.isPhone && menu.open) {
      bottomSheet.configure(menu.Component);
    }
  }, [bottomSheet, menu.Component, menu.open, device.isPhone]);
  // when changing pages, reset menu
  useEffect(() => menu.reset, [menu, pathname]);

  useEffect(() => {
    setShow(menu.open);
  }, [menu.open]);

  return device.measured === false || device.isPhone ? null : (
    <Menu
      key={menu.Component}
      disableScrollLock
      keepMounted={false}
      anchorEl={
        menu.anchor ||
        (menu.anchorId ? document.getElementById(menu.anchorId) : undefined)
      }
      open={menu.open}
      onClose={() => setShow(false)}
      slotProps={{ paper: { sx: { ...paperSx, ...menu.sx.paper } } }}
      MenuListProps={{
        sx: { minWidth: 112, maxWidth: 280, ...menu.sx.menuList }
      }}
      {...menu.options}
      TransitionComponent={Fade}
      TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
    >
      <Collapse
        appear
        in={show}
        onExited={menu.reset}
        sx={theme => ({
          ...theme.motion.transition[menu.open ? "enter" : "exit"].temp
        })}
      >
        <menu.Component state={menu.state} />
      </Collapse>
    </Menu>
  );
});

export const paperSx = {
  maxHeight: "50vh",
  bgcolor: "surface.container.color",
  boxShadow: theme => theme.elevation[2],
  borderRadius: theme => theme.shape.xs.round
};
