"use client";
import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";
import { IconButton, Stack, Typography } from "@mui/material";

import ToolTip from "../../Tooltip";

export default function Destination({
  text,
  disabled,
  Off = () => null,
  On = () => null,
  path = "",
  params = "",
  match = "",
  noMatch = ""
}) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const selected =
    pathname === path &&
    ((!match && !noMatch) ||
      (match && searchParams.has(match)) ||
      (noMatch && searchParams.has(noMatch) === false));
  const Icon = selected ? On : Off;

  return (
    <ToolTip placement="right" title={disabled ? "Coming soon" : ""}>
      <Stack
        alignContent="center"
        spacing={0.25}
        height={56}
        component={disabled || !path ? undefined : Link}
        href={disabled || !path ? undefined : path + params}
        disabled={disabled}
        sx={{
          opacity: disabled ? 0.5 : undefined,
          cursor: "pointer",
          textDecoration: "none",
          "&:hover button": {
            bgcolor: "surface.container.color",
            color: disabled
              ? undefined
              : selected
              ? "secondary.on.container"
              : "surface.on.color"
          },
          "&:hover svg": theme => ({
            width: 20,
            height: 20,
            strokeWidth: selected ? undefined : 0.5,
            stroke: selected ? undefined : theme.palette.surface.on.variant
          })
        }}
      >
        <IconButton
          disabled={disabled}
          aria-label={text}
          sx={theme => ({
            width: 56,
            height: 32,
            borderRadius: theme.shape.md.round,
            bgcolor: selected ? "secondary.container" : undefined,
            transition: theme.transitions.create("all"),
            ...theme.motion.transition.enter.temp
          })}
        >
          <Icon
            sx={theme => ({
              width: selected ? 20 : 18,
              height: selected ? 20 : 18,
              color: selected ? "secondary.on.container" : "surface.on.variant",
              transition: theme.transitions.create("all"),
              ...theme.motion.transition.enter.perm
            })}
          />
        </IconButton>
        <Typography
          variant={`labelMd${selected ? "Prominent" : ""}`}
          align="center"
          sx={{
            color: selected ? "surface.on.color" : "surface.on.variant",
            ":hover": {
              color: disabled ? undefined : "surface.on.color"
            }
          }}
        >
          {text}
        </Typography>
      </Stack>
    </ToolTip>
  );
}
