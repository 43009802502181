import { Tooltip } from "@mui/material";

const ToolTip = ({ children, title, ...props }) => (
  <Tooltip
    arrow
    title={title}
    describeChild
    componentsProps={{
      tooltip: {
        sx: {
          typography: "bodySm",
          bgcolor: "surface.inverse.color",
          color: "surface.inverse.on"
        }
      },
      arrow: { sx: { color: "surface.inverse.color" } }
    }}
    {...props}
  >
    <span>{children}</span>
  </Tooltip>
);

export default ToolTip;
